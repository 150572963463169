.img-hover-zoom {
 
  
 
    overflow: hidden;

  }

  

   .texto-explicacion{

    padding: 0px 10px 10px 10px;

    text-align: justify;

    font-size: 18px;

   }

  

   .titulo-explicacion{

     padding: 10px;

     font-size: 24px;

   }

  

  .img-hover-zoom img {

    transition: transform .9s ease;

  }



  .img-hover-zoom:hover img {

    transform: scale(1.1);

  }



   

   .text-h2{

     padding: 0px;

     font-size: 24px;

     animation: nm-intro-fadein .5s cubic-bezier(.15,0,.25,1) forwards,nm-intro-move .5s cubic-bezier(.15,0,.25,1) forwards;

  

   }    

   .fondo{

     background: black;

     color: white;

     padding: 30px;

     font-size: 12px;

     text-align: justify;

   }

   .image-container {


          display: flex;
          justify-content: space-around;
          flex-wrap: wrap; /* Permite que las imágenes se ajusten si el espacio es pequeño */

      }

    .text-center{ text-align: center;}

   .image-container img {

     width: 100%;

     height: auto;

     display: block;

   }



   .image-title {

     position: absolute;

     bottom: 0;

     color: white;

     width: 100%;

     text-align: left;

     padding: 40px 50px;

     font-size: 36px;

     background: rgba(0, 0, 0, 0.5);

   }

  

  @font-face {

      font-weight: 400 700;

      font-family: 'AudiType';

      font-style: normal;

      font-stretch: 100% 130%;

      src: url(https://assets.audi.com/audi-fonts/1/AudiTypeVF.woff2) format('woff2-variations');

      font-display: swap;

  }



  @font-face {

      font-family: "audi-iconfont-3.18";

      src: url(https://react.ui.audi/assets/webfonts/icon/audi-iconfont_3.18.woff2) format('woff2-variations');

      font-display: block;

      font-weight: normal;

      font-style: normal;

  }

   @font-face {

font-family: AudiType-Normal;

font-stretch: 100%;

font-style: normal;

font-weight: 400;

src: url(https://assets.audi.com/audi-fonts/1/AudiTypeVF.woff2) format("woff2-variations"),

url(https://assets.audi.com/audi-fonts/1/AudiType-Normal.woff2) format("woff2"),

url(https://assets.audi.com/audi-fonts/1/AudiType-Normal.woff) format("woff"),

url(https://assets.audi.com/audi-fonts/1/AudiType-Normal.ttf) format("truetype")

}

   body  {

  font-family: AudiType,Helvetica,Arial,sans-serif !important;

}



.timer-container {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.kpi-container{

  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.month-container{
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.kpi {
  font-size: 14px;
  color: #333;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 5px;
  text-align: center;
}

.timer {
  font-size: 48px;
  color: #333;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.text-input{
  width: 100%;
  padding: 15px;
  border: 0;
  font-family: AudiType,Helvetica,Arial,sans-serif;
  font-size: 15px;
  border-radius: 5px;
  display: block;
}

.text-input:focus{
  border-color: #333;
  border: 1px solid #333;
}

.month-header{
  font-size: 24px;
}