.gradientDivLeft {
  position: absolute;
  top: 100px;
  left: 0px;
  width: 50%;
  height: 500px;
  filter: alpha(opacity=20);
  /* IE */
  -moz-opacity: 0.2;
  /* Mozilla */
  opacity: 0.2;
  /* CSS3 */

  filter: blur(100px);

  background: -moz-linear-gradient(
    -45deg,
    rgba(84, 84, 212, 0.42) 0%,
    rgba(84, 84, 212, 1) 100%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    -45deg,
    left bottom,
    color-stop(0%, rgba(84, 84, 212, 0.42)),
    color-stop(100%, rgba(84, 84, 212, 1))
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    -45deg,
    rgba(84, 84, 212, 0.42) 0%,
    rgba(84, 84, 212, 1) 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    -45deg,
    rgba(84, 84, 212, 0.42) 0%,
    rgba(84, 84, 212, 1) 100%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    -45deg,
    rgba(84, 84, 212, 0.42) 0%,
    rgba(84, 84, 212, 1) 100%
  ); /* IE10+ */
  background: linear-gradient(
    -45deg,
    rgba(84, 84, 212, 0.42) 0%,
    rgba(84, 84, 212, 1) 100%
  ); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5454D4', endColorstr='#5454D4',GradientType=0 ); /* IE6-9 */
}

.gradientDivRight {
  position: absolute;
  top: 150px;
  right: 0px;
  width: 50%;
  height: 500px;
  filter: alpha(opacity=27);
  /* IE */
  -moz-opacity: 0.27;
  /* Mozilla */
  opacity: 0.27;
  /* CSS3 */

  filter: blur(100px);

  background: -moz-linear-gradient(
    -45deg,
    rgba(251, 168, 28, 0.26) 0%,
    rgba(224, 86, 136, 0.15) 100%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    -45deg,
    left bottom,
    color-stop(0%, rgba(251, 168, 28, 0.26)),
    color-stop(100%, rgba(224, 86, 136, 0.15))
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    -45deg,
    rgba(251, 168, 28, 0.26) 0%,
    rgba(224, 86, 136, 0.15) 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    -45deg,
    rgba(251, 168, 28, 0.26) 0%,
    rgba(224, 86, 136, 0.15) 100%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    -45deg,
    rgba(251, 168, 28, 0.26) 0%,
    rgba(224, 86, 136, 0.15) 100%
  ); /* IE10+ */
  background: linear-gradient(
    -45deg,
    rgba(251, 168, 28, 0.26) 0%,
    rgba(224, 86, 136, 0.15) 100%
  ); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5454D4', endColorstr='#5454D4',GradientType=0 ); /* IE6-9 */
}

@media (max-width: 600px) {
  .gradientDivLeft {
    width: 100%;
    top: 50px;
    height: "350px";
  }
  .gradientDivRight {
    width: 100%;
    top: 400px;
  }
}

footer {
  padding: 25px 0;
  text-align: center;
}

footer span.copyright {
  font-size: 90%;
  line-height: 40px;
  text-transform: none;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

footer ul.quicklinks {
  font-size: 90%;
  line-height: 40px;
  margin-bottom: 0;
  text-transform: none;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

ul.social-buttons {
  margin-bottom: 0;
}

ul.social-buttons li a {
  font-size: 20px;
  line-height: 50px;
  display: block;
  width: 50px;
  height: 50px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  color: black;
  border-radius: 100%;
  outline: none;
  background-color: #fff;
}

ul.social-buttons li a:active,
ul.social-buttons li a:focus,
ul.social-buttons li a:hover {
  background-color: #00bfd8;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.Mui-disabled {
  display: none !important;
}
